function find(item, key, val) {
  function findVal(item) {
    return item[key] === val;
  }
  return item.find(findVal);
}

function removeIndex(array, from, to) {
  let rest = array.slice((to || from) + 1 || array.length);
  array.length = from < 0 ? array.length + from : from;
  return array.push.apply(array, rest);
}

function isEmptyObj(obj) {
  for (var prop in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(prop)) return false;
  }
  return JSON.stringify(obj) === JSON.stringify({});
}

export { find, removeIndex, isEmptyObj };

